import { AppBar, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../Components/TabPanel";
import ConturiClientiTabel from "./tabel";
import VerificareSivecoCodClient from "./VerificareSivecoCodClient";
import React from "react";

export default function ConturiClientiPage() {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Tabel conturi clienți" />
          <Tab label="Verificare codClient și nrContract Siveco" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <ConturiClientiTabel />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <VerificareSivecoCodClient />
      </TabPanel>
    </div>
  );
}
