import { Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import FormInput from "../../Components/FormInput";
import { ContClient, deleteContClient, getAllConturiClienti } from "../../utils/api/conturiClienti";
import { DeleteRounded } from "@material-ui/icons";
import AlertDialog from "../../Components/AlertDialog";
import Spinner from "../../Components/Spinner";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(8),
  },
  saveButton: {
    alignSelf: "center",
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  searchPaper: {
    // margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  searchContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
  },
  searchInput: {
    minWidth: 300,
  },
}));

export default function ConturiClientiTabel() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [items, setItems] = React.useState<ContClient[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [idContDeSters, setIdContDeSters] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const getItems = async () => {
      try {
        const apiResponse = await getAllConturiClienti(0, { searchText });
        if (apiResponse.data) setItems(apiResponse.data);
      } catch (error) {
        console.log(error);
        enqueueSnackbar("A apărut o eroare la preluarea informațiilor", { variant: "error" });
      }
    };
    const fetchTimeout = setTimeout(getItems, 500);
    return () => {
      clearTimeout(fetchTimeout);
    };
  }, [searchText, enqueueSnackbar]);

  const changeSearchText = (name: string, value: string) => {
    setSearchText(value);
  };

  const deleteItem = (id: string) => {
    setIdContDeSters(id);
  };

  const tryDeleteCont = async () => {
    console.log("idContDeSters", idContDeSters);
    if (idContDeSters == null) setIdContDeSters(null);
    else {
      setIdContDeSters(null);
      setIsLoading(true);
      try {
        const apiResponse = await deleteContClient(idContDeSters);
        if (apiResponse.type) {
          enqueueSnackbar("Contul a fost șters", { variant: "success" });
          setItems(items.filter((item) => item._id !== idContDeSters));
        } else enqueueSnackbar("A apărut o eroare la ștergere", { variant: "error" });
      } catch (error) {
        console.error(error);
        enqueueSnackbar("A apărut o eroare la ștergere", { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const closeDeleteDialog = () => {
    setIdContDeSters(null);
  };

  return (
    <Grid item xs={12} className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Tabel conturi clienți
      </Typography>
      <Grid item xs={12} className={classes.searchContainer}>
        <Paper className={classes.searchPaper}>
          <FormInput
            className={classes.searchInput}
            onChange={changeSearchText}
            label="Caută"
            name="cauta"
            value={searchText}
            // required
            // regexTest={ALFA_NUMERIC_REGEX}
            // helperText={ALFA_NUMERIC_ERROR}
          />
        </Paper>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="Tabel conturi clienți">
          <TableHead>
            <TableRow>
              <TableCell>Denumire</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Cod abonat</TableCell>
              <TableCell>Număr contract</TableCell>
              <TableCell>Cont activ</TableCell>
              <TableCell>Acțiuni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell>{item.denumire}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.codClient}</TableCell>
                <TableCell>{item.nrContract}</TableCell>
                <TableCell>{item.active ? "Da" : "Nu"}</TableCell>
                <TableCell>
                  {isLoading && idContDeSters === item._id ? (
                    <Spinner
                      containerStyle={{
                        width: 20,
                        height: 20,
                        marginLeft: 4,
                      }}
                      show={isLoading}
                    />
                  ) : (
                    <IconButton color="secondary" component="span" aria-label="șterge înregistrare" onClick={() => deleteItem(item._id)}>
                      <DeleteRounded />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        open={idContDeSters !== null}
        title="Vreți să ștergeți contul?"
        message="Ștergerea acestui cont va fi definită. Confirmați această ștergere"
        onAccept={tryDeleteCont}
        onCancel={closeDeleteDialog}
      />
    </Grid>
  );
}
