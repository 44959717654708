import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
type AlertDialogProps = {
  open: boolean;
  onCancel: () => void;
  onAccept: () => void;
  title: string;
  message: string;
  cancelButtontext?: string;
  acceptButtonText?: string;
};

export default function AlertDialog({
  open,
  onCancel,
  onAccept,
  title,
  message,
  cancelButtontext = "Nu acum",
  acceptButtonText = "Confirm",
}: AlertDialogProps) {
  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelButtontext}
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
