import { Button, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AlertDialog from "../../Components/AlertDialog";
import FormInput from "../../Components/FormInput";
import { deleteAnuntById, getAllAnunturiPublice, getAnuntById, postNewAnuntPublic, putAnuntPublic } from "../../utils/api/anunturiPublice";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../utils/regex";
import AnunturiPubliceTabel from "./tabel";
import { useSnackbar } from "notistack";
import HtmlEditor from "../FAQ/HtmlEditor";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(4),
    maxWidth: 250,
  },
  modal: {
    position: "absolute",
    width: "100%",
  },
  modalContent: {
    width: "80%",
    margin: "5vh auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
}));

const initialData = {
  titlu: "",
  continut: "",
};

export default function AnunturiPublicePage() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  // return (
  //   <Grid item xs={12} className={classes.paper}>
  //     <HtmlEditor />
  //   </Grid>
  // );
  const [items, setItems] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [idAnuntDeSters, setIdAnuntDeSters] = useState(null);

  const htmlEditorRef = React.useRef();

  useEffect(() => {
    const getItems = async () => {
      try {
        const apiResponse = await getAllAnunturiPublice();
        if (apiResponse.data) setItems(apiResponse.data);
      } catch (error) {
        console.log(error);
        enqueueSnackbar("A apărut o eroare la preluarea informațiilor", { variant: "error" });
      }
    };
    getItems();
  }, []);

  const onAdd = (tip) => {
    setModalData({
      ...initialData,
      isNew: true,
    });
  };

  const onEdit = async (itemId) => {
    try {
      const apiResponse = await getAnuntById(itemId);
      if (apiResponse.type) setModalData(apiResponse.data);
    } catch (error) {
      console.error(error);
    }
    // setModalData(items.filter((item) => item._id === itemId)[0]);
  };

  const closeModal = () => setModalData(null);

  const changeModalData = (name, value) => {
    setModalData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const onInitHtmlEditor = (evt, editor) => {
    htmlEditorRef.current = editor;
  };

  const onDirtyHtmlEditor = () => {};

  const tryAddNewAnuntPublic = async () => {
    try {
      const apiResponse = await postNewAnuntPublic({
        // tip: modalData.tip,
        titlu: modalData.titlu,
        continut: htmlEditorRef.current.getContent(),
      });
      if (apiResponse.type) {
        setItems((oldItems) => [...oldItems, { _id: apiResponse.data, titlu: modalData.titlu, continut: htmlEditorRef.current.getContent() }]);
        setModalData(null);
        enqueueSnackbar("Elementul a fost adăugat cu succes!", { variant: "success" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
    }
  };

  const tryEditAnuntPublic = async () => {
    // console.log(modalData.continut);
    try {
      const apiResponse = await putAnuntPublic({
        _id: modalData._id,
        // tip: modalData.tip,
        titlu: modalData.titlu,
        continut: htmlEditorRef.current.getContent(),
      });
      if (apiResponse.type) {
        setModalData(null);
        setItems((oldItems) => oldItems.map((item) => (item._id === modalData._id ? modalData : item)));
        enqueueSnackbar("Elementul a fost modificat cu succes!", { variant: "success" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
    }
  };

  const onDelete = async (itemId) => {
    setIdAnuntDeSters(itemId);
  };

  const closeDeleteDialog = () => {
    setIdAnuntDeSters(null);
  };

  const tryDeleteItem = async () => {
    try {
      const apiResponse = await deleteAnuntById(idAnuntDeSters);
      if (apiResponse.type) {
        enqueueSnackbar("Elementul a fost șters cu succes!", { variant: "success" });
        setItems((oldItems) => oldItems.filter((item) => item._id !== idAnuntDeSters));
        setIdAnuntDeSters(null);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
      setIdAnuntDeSters(null);
    }
  };

  if (modalData != null) console.log(modalData.continut);

  return (
    <div>
      <AnunturiPubliceTabel tip={0} items={items} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
      <Modal open={modalData} onClose={closeModal} disableEnforceFocus disableAutoFocus disablePortal>
        {modalData && (
          // <Grid container className={classes.modal}>
          <Grid item xs={10} className={classes.modalContent}>
            <Typography variant="h5">{`${modalData.isNew ? "Adaugă" : "Editează"} anunt public`}</Typography>
            <form>
              <FormInput
                className={classes.inputStyle}
                fullWidth
                onChange={changeModalData}
                label="Titlu"
                name="titlu"
                value={modalData.titlu}
                required
                regexTest={ALFA_NUMERIC_REGEX}
                helperText={ALFA_NUMERIC_ERROR}
              />
              <div className={classes.inputStyle}>
                <HtmlEditor initialValue={modalData.continut} onInit={onInitHtmlEditor} onDirty={onDirtyHtmlEditor} />
              </div>
            </form>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={modalData.isNew ? tryAddNewAnuntPublic : tryEditAnuntPublic}
            >
              {modalData.isNew ? "Adaugă" : "Editează"}
            </Button>
          </Grid>
          // </Grid>
        )}
      </Modal>
      <AlertDialog
        open={idAnuntDeSters != null}
        title="Vreți să ștergeți elementul?"
        message="Ștergerea acestui element va fi definită. Confirmați această ștergere"
        onAccept={tryDeleteItem}
        onCancel={closeDeleteDialog}
      />
    </div>
  );
}
