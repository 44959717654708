import { apiBase, ApiResponse, convertFilterToRequestParams, customGet, deleteById } from "./utils";

const baseUrl = `${apiBase}/adminApi/conturiClienti`;

export type ContClient = {
  _id: string;
  active: boolean;
  email: string;
  denumire: string;
  codClient: string;
  nrContract: string;
};

export function getAllConturiClienti(pageNumber: number, filters: { searchText: string }): Promise<ApiResponse<ContClient[]>> {
  const params = convertFilterToRequestParams(filters);
  return customGet(`${baseUrl}/all/${pageNumber}?${params}`) as Promise<ApiResponse<ContClient[]>>;
}

interface CheckCodClientResponse extends ApiResponse<null> {
  clientGasit: boolean;
}
export function getCheckCodClient({ codClient, nrContract }: { codClient: string; nrContract: string }): Promise<CheckCodClientResponse> {
  return customGet(`${baseUrl}/checkCodClient/${codClient}/${nrContract}`) as Promise<CheckCodClientResponse>;
}

export function deleteContClient(id: string): Promise<ApiResponse<null>> {
  return deleteById(baseUrl, id) as Promise<ApiResponse<null>>;
}
