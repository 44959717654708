import { Button, FormControlLabel, Grid, makeStyles, Paper, Switch, Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import React from "react";
import { Editor as TinyMCEEditor } from "tinymce";
import AlertDialog from "../../Components/AlertDialog";
import FormInput from "../../Components/FormInput";
import Spinner from "../../Components/Spinner";
import { returnDenumireLunaByValue } from "../../utils";
import { ApiResponeCountFacturi, getCountFacturiDeTrimis, postStartTransmitere, postTestTransmitere } from "../../utils/api/transmitereEmailFacturi";
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  MULTIPLE_CODCLIENT_ERROR,
  MULTIPLE_CODCLIENT_REGEX,
  MULTIPLE_EMAIL_ERROR,
  MULTIPLE_EMAIL_REGEX,
} from "../../utils/regex";
import HtmlEditor from "../FAQ/HtmlEditor";
import { InfoMsgType } from "../../utils/types";
import { getEmailTemplateById } from "../../utils/api/emailTemplate";

const EMAIL_FACTURI_AUTOMATE_TEMPLATE_ID = "66fa8949b3fb3fe430602720";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
  paper: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  datePickerMargin: {
    marginTop: theme.spacing(2),
  },
  containerPerioada: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    // alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
  tabList: {
    backgroundColor: "#3f51b5",
    color: "#FFFFFF",
    marginTop: theme.spacing(2),
  },
}));

type FormData = {
  denumire: string;
  inceputDataEmitere: Date;
  sfarsitDataEmitere: Date;
  isGrupat: boolean;
  contentByTipEmail: {
    tip: string;
    title: string;
    htmlMessage: string;
  }[];
  testEmails: string;
  coduriClienti: string;
};

const validateFormDataTest = ({ inceputDataEmitere, sfarsitDataEmitere, testEmails, coduriClienti, contentByTipEmail }: FormData, tip: number) => {
  return (
    inceputDataEmitere != null &&
    inceputDataEmitere.getMonth() != null &&
    sfarsitDataEmitere != null &&
    sfarsitDataEmitere.getMonth() != null &&
    MULTIPLE_EMAIL_REGEX.test(testEmails) &&
    tip > -1 &&
    (contentByTipEmail[tip].title != null || contentByTipEmail[tip].title != "") &&
    (coduriClienti == null || coduriClienti == "" || MULTIPLE_CODCLIENT_REGEX.test(coduriClienti))
  );
};

const validateFormDataSend = ({ inceputDataEmitere, sfarsitDataEmitere, contentByTipEmail }: FormData, tip: number) =>
  inceputDataEmitere != null &&
  inceputDataEmitere.getMonth() != null &&
  sfarsitDataEmitere != null &&
  sfarsitDataEmitere.getMonth() != null &&
  tip > -1 &&
  (contentByTipEmail[tip].title != null || contentByTipEmail[tip].title != "");

const dateNow = new Date();

const createDefaultHtmlEmailMessage = (htmlMessage: string, dataSfarsitEmitere: Date) =>
  htmlMessage.replace("{{lunaFactura}}", returnDenumireLunaByValue(dataSfarsitEmitere.getMonth() - 1));

export default function StartSendEmailuriFacturi() {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [showAlertIncepeTransmitere, setShowAlertIncepeTransmitere] = React.useState(false);
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  });
  const [infoMsgSend, setInfoMsgSend] = React.useState<InfoMsgType>({
    isLoading: false,
  });
  const [infoMsgTestSend, setInfoMsgTestSend] = React.useState<InfoMsgType>({
    isLoading: false,
  });

  const [tabTipEmail, setTabTipEmail] = React.useState(-1);
  const [formData, setFormData] = React.useState<FormData>({
    denumire: `Transmitere facturi pentru luna ${returnDenumireLunaByValue(dateNow.getMonth() - 1)}`,
    inceputDataEmitere: new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 20),
    sfarsitDataEmitere: new Date(),
    isGrupat: false,
    contentByTipEmail: [],
    testEmails: "",
    coduriClienti: "",
  });

  const [countFacturi, setCountFacturi] = React.useState<ApiResponeCountFacturi & { isLoading: boolean }>({
    isLoading: true,
    totalFacturi: -1,
    totalEmailuri: -1,
    totalFacturiCuClientiCuEmail: -1,
    tipuriEmailuri: [],
  });

  const htmlEditorRef = React.useRef<TinyMCEEditor | null>(null);

  React.useEffect(() => {
    const fetchCountFacturi = async (inceputDataEmitere: Date, sfarsitDataEmitere: Date) => {
      try {
        const apiResponse = await getCountFacturiDeTrimis({ inceputDataEmitere, sfarsitDataEmitere });
        const apiResponseEmailTemplate = await getEmailTemplateById(EMAIL_FACTURI_AUTOMATE_TEMPLATE_ID);
        // console.log(apiResponseEmailTemplate);
        // console.log(apiResponse);
        if (apiResponse.type && apiResponseEmailTemplate.type) {
          setCountFacturi({ isLoading: false, ...apiResponse.data });
          setFormData((oldState) => ({
            ...oldState,
            contentByTipEmail: apiResponse.data.tipuriEmailuri.map((item) => ({
              tip: item,
              title: "Factura dvs. de gaze naturale",
              htmlMessage: createDefaultHtmlEmailMessage(apiResponseEmailTemplate.data.htmlMessage, oldState.sfarsitDataEmitere),
            })),
          }));
          if (apiResponse.data.tipuriEmailuri.length > 0) setTabTipEmail(0);
        }
        // setTabTipEmail(apiResponse.data.tipuriEmailuri[0]);
      } catch (error) {
        console.error(error);
        setCountFacturi((oldState) => ({ ...oldState, isLoading: false }));
      }
    };

    if (formData.inceputDataEmitere != null && formData.sfarsitDataEmitere != null)
      fetchCountFacturi(formData.inceputDataEmitere, formData.sfarsitDataEmitere);
  }, [formData.inceputDataEmitere, formData.sfarsitDataEmitere]);

  // console.log(formData, tabTipEmail);

  const changeFormData = (name: string, value: string) => {
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const handleChangeIsGrupat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFormData((oldData) => ({
      ...oldData,
      isGrupat: checked,
    }));
  };
  const handleInceputDataEmitereChange = (date: Date | null) => {
    // console.log(date, value);
    // console.log(date.toUTCString());
    if (date != null)
      setFormData((oldData) => ({
        ...oldData,
        inceputDataEmitere: date,
      }));
  };

  const handleSfarsitDataEmitereChange = (date: Date | null) => {
    // console.log(date, value);
    // console.log(date.toUTCString());
    if (date != null)
      setFormData((oldData) => ({
        ...oldData,
        sfarsitDataEmitere: date,
      }));
  };

  const onInitHtmlEditor = (evt: any, editor: TinyMCEEditor) => {
    htmlEditorRef.current = editor;
  };

  const onDirtyHtmlEditor = () => {};

  const changeEmailTitle = (name: string, value: string) => {
    setFormData((oldData) => ({
      ...oldData,
      contentByTipEmail: oldData.contentByTipEmail.map((item, index) =>
        index === tabTipEmail
          ? {
              ...item,
              title: value,
            }
          : item
      ),
    }));
  };

  const handleTabTipEmailChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTabTipEmail(value);
  };

  const trySendTestTransmitere = async () => {
    setInfoMsgTestSend({
      isLoading: true,
    });
    try {
      const apiReponse = await postTestTransmitere({
        testEmails: formData.testEmails,
        coduriClienti: formData.coduriClienti,
        denumire: formData.denumire,
        isGrupat: formData.isGrupat,
        inceputDataEmitere: formData.inceputDataEmitere,
        sfarsitDataEmitere: formData.sfarsitDataEmitere,
      });
      if (apiReponse.type)
        setInfoMsgTestSend({
          isLoading: false,
          status: "ok",
          msg: "Transmitere a început cu succes",
        });
      else
        setInfoMsgTestSend({
          isLoading: false,
          status: "error",
          msg: "Eroare începere transmitere test emailuri facturi",
        });
    } catch (error) {
      setInfoMsgTestSend({
        isLoading: false,
        status: "error",
        msg: "Eroare începere transmitere test emailuri facturi",
      });
    }
  };

  const trySendStartTransmitere = async () => {
    setShowAlertIncepeTransmitere(false);
    setInfoMsgSend({
      isLoading: true,
    });
    try {
      const apiReponse = await postStartTransmitere({
        denumire: formData.denumire,
        isGrupat: formData.isGrupat,
        inceputDataEmitere: formData.inceputDataEmitere,
        sfarsitDataEmitere: formData.sfarsitDataEmitere,
      });
      if (apiReponse.type)
        setInfoMsgSend({
          isLoading: false,
          status: "ok",
          msg: "Transmitere a început cu succes",
        });
      else
        setInfoMsgSend({
          isLoading: false,
          status: "error",
          msg: "Eroare începere transmitere test emailuri facturi",
        });
    } catch (error) {
      setInfoMsgSend({
        isLoading: false,
        status: "error",
        msg: "Eroare începere transmitere test emailuri facturi",
      });
    }
  };

  return (
    <Grid container className={classes.column}>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" color="primary" align="center">
            Perioadă facturi + statistica emailuri
          </Typography>
          <form className={classes.form}>
            <FormInput
              key={1}
              id="denumire"
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Denumire transmitere"
              name="denumire"
              value={formData.denumire}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />
            <div className={`${classes.containerPerioada} ${classes.datePickerMargin}`}>
              <DatePicker
                // disableToolbar
                views={["year", "month", "day"]}
                openTo="day"
                format="dd-MM-yyyy"
                label="Început perioadă"
                value={formData.inceputDataEmitere}
                onChange={handleInceputDataEmitereChange}
              />
              <DatePicker
                // disableToolbar
                views={["year", "month", "day"]}
                openTo="day"
                format="dd-MM-yyyy"
                label="Sfârșit perioadă"
                value={formData.sfarsitDataEmitere}
                onChange={handleSfarsitDataEmitereChange}
              />
            </div>
            <div className={classes.containerPerioada}>
              <FormControlLabel
                control={<Switch checked={formData.isGrupat} onChange={handleChangeIsGrupat} name="isGrupat" color="primary" />}
                label="Grupeaza facturi per cod client"
              />
            </div>
            {countFacturi.isLoading ? (
              <Spinner
                containerStyle={{
                  width: 41,
                  height: 41,
                  margin: "40px auto 0px auto",
                }}
                show={true}
              />
            ) : countFacturi.totalFacturi > -1 ? (
              <Grid className={classes.inputStyle}>
                <Grid className={classes.containerPerioada}>
                  <Typography color="textPrimary">Total facturi de trimis:</Typography>
                  <Typography color="primary">{countFacturi.totalFacturi}</Typography>
                </Grid>
                <Grid className={classes.containerPerioada}>
                  <Typography color="textPrimary">Total clienți cu email: </Typography>
                  <Typography color="primary">{countFacturi.totalEmailuri}</Typography>
                </Grid>
                <Grid className={classes.containerPerioada}>
                  <Typography color="textPrimary">Total clienți cu email și factură de trimis: </Typography>
                  <Typography color="primary">{countFacturi.totalFacturiCuClientiCuEmail}</Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.inputStyle}>
                <Typography color="error">Eroare preluare informații despre facturi</Typography>
              </Grid>
            )}
          </form>
          <Spinner
            containerStyle={{
              width: 41,
              height: 41,
              margin: "40px auto 0px auto",
            }}
            show={infoMsg.isLoading}
          />
          <div
            style={{
              padding: "30px 0px",
              // marginTop: 32,
              textAlign: infoMsg.msg != null && infoMsg.msg.length > 44 ? "justify" : "center",
              fontSize: 18,
              color: infoMsg.status === "error" ? theme.palette.error.main : theme.palette.primary.main,
            }}
          >
            {infoMsg.msg}
          </div>
        </Paper>
      </Grid>
      <Grid xs={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" color="primary" align="center">
            Editează
          </Typography>
          {/* @ts-ignore */}
          <Tabs
            className={classes.tabList}
            backgroundColor="primary"
            value={tabTipEmail}
            onChange={handleTabTipEmailChange}
            aria-label="Taburi tip email"
          >
            {countFacturi.tipuriEmailuri.map((item) => (
              <Tab key={item} label={item ?? "Fără tip"} />
            ))}
          </Tabs>
          {formData.contentByTipEmail[tabTipEmail] != null && [
            <FormInput
              key={1}
              id="title"
              className={classes.inputStyle}
              fullWidth
              onChange={changeEmailTitle}
              label="Titlu email"
              name="title"
              value={formData.contentByTipEmail[tabTipEmail].title}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />,
            <div key={2} className={classes.inputStyle}>
              <HtmlEditor initialValue={formData.contentByTipEmail[tabTipEmail].htmlMessage} onInit={onInitHtmlEditor} onDirty={onDirtyHtmlEditor} />
              {/* <HtmlEditor value={formData.contentByTipEmail[tabTipEmail].htmlMessage} handleEditorChange={changeHtmlMessage} height={400} /> */}
            </div>,
          ]}
        </Paper>
      </Grid>
      <Grid xs={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" color="primary" align="center">
            Testează
          </Typography>

          <form id="transmitere" className={classes.form}>
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Adrese e-mail test (separate prin virgula)"
              name="testEmails"
              value={formData.testEmails}
              required
              regexTest={MULTIPLE_EMAIL_REGEX}
              helperText={MULTIPLE_EMAIL_ERROR}
            />
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Coduri clienti de inclus in test (optional)"
              name="coduriClienti"
              value={formData.coduriClienti}
              regexTest={MULTIPLE_CODCLIENT_REGEX}
              helperText={MULTIPLE_CODCLIENT_ERROR}
            />
            <Typography className={classes.inputStyle} component="p" color="textSecondary" align="justify">
              Orice test va include 5 facturi random plus cele pentru codurile de clienti introduse în câmpul de mai sus
            </Typography>
            {infoMsgTestSend.msg != null && (
              <Typography className={classes.inputStyle} color={infoMsgSend.status === "error" ? "error" : "textPrimary"}>
                {infoMsgTestSend.msg}
              </Typography>
            )}
            {infoMsgTestSend.isLoading ? (
              <Grid className={classes.column}>
                <Spinner
                  containerStyle={{
                    width: 41,
                    height: 41,
                    margin: "40px auto 0px auto",
                  }}
                  show={true}
                />
              </Grid>
            ) : (
              <Grid className={classes.column}>
                <Button
                  disabled={!validateFormDataTest(formData, tabTipEmail)}
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  onClick={trySendTestTransmitere}
                >
                  Testează
                </Button>
              </Grid>
            )}
          </form>
        </Paper>
      </Grid>
      <Grid xs={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" color="primary" align="center">
            Transmite facturi
          </Typography>
          {infoMsgSend.msg != null && (
            <Typography className={classes.inputStyle} color={infoMsgSend.status === "error" ? "error" : "textPrimary"}>
              {infoMsgSend.msg}
            </Typography>
          )}
          {infoMsgSend.isLoading ? (
            <Grid className={classes.column}>
              <Spinner
                containerStyle={{
                  width: 41,
                  height: 41,
                  margin: "40px auto 0px auto",
                }}
                show={true}
              />
            </Grid>
          ) : (
            <Grid className={classes.column}>
              <Button
                disabled={!validateFormDataSend(formData, tabTipEmail)}
                className={classes.saveButton}
                variant="contained"
                color="secondary"
                onClick={() => setShowAlertIncepeTransmitere(true)}
              >
                Trimite facturi
              </Button>
            </Grid>
          )}
        </Paper>
      </Grid>
      <AlertDialog
        open={showAlertIncepeTransmitere}
        title="Vreți să începeți transmitere de emailuri?"
        message="Verificați că titlul și mesajul emailul este setat corect înainte de a confirma!"
        onAccept={trySendStartTransmitere}
        onCancel={() => setShowAlertIncepeTransmitere(false)}
      />
    </Grid>
  );
}
