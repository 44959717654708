import { AppBar, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import TabPanel from "../../Components/TabPanel";
import BineAiVenitEmailTemplate from "./AnuntPublicGeneral";
import ContSinronizatCuSivecoTemplate from "./contSincronizatCuSiveco";
import DeschiderePerioadaAutocitire from "./DeschiderePerioadaAutocitire";
import InviteClientEmailTemplate from "./inviteClient";

export default function EmailuriAutomatePage() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTabValue(value);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Email deschidere perioadă autocitire" />
          <Tab label="Link crearea cont precompletat" />
          <Tab label="Cont client sincronizat cu siveco" />
          <Tab label="Anunț public general" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <DeschiderePerioadaAutocitire />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <InviteClientEmailTemplate />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ContSinronizatCuSivecoTemplate />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <BineAiVenitEmailTemplate />
      </TabPanel>
    </div>
  );
}
