import { Button, Grid, makeStyles, Paper, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import FormInput from "../../Components/FormInput";
import Spinner from "../../Components/Spinner";
import { postLinkInvite } from "../../utils/api/linkInvite";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX, EMAIL_ERROR, EMAIL_REGEX } from "../../utils/regex";
import { InfoMsgType } from "../../utils/types";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonsContainer: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    // alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
}));

type FormData = {
  email: string;
  codClient: string;
  nrContract: string;
};

const validateFormData = ({ codClient, nrContract, email }: FormData) =>
  ALFA_NUMERIC_REGEX.test(codClient) && ALFA_NUMERIC_REGEX.test(nrContract) && EMAIL_REGEX.test(email);

export default function LinkInviteForm() {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({ isLoading: false });
  const [formData, setFormData] = React.useState({
    email: "",
    codClient: "",
    nrContract: "",
  });

  const changeFormData = (name: string, value: string) => {
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const tryPostLinkInvite = async () => {
    try {
      setInfoMsg({
        isLoading: true,
      });
      const apiResponse = await postLinkInvite(formData);
      // console.log(apiResponse);
      if (apiResponse.type) setInfoMsg({ isLoading: false, msg: "Email transmis cu succes către client", status: "ok" });
      else if (apiResponse.contWithEmailExists)
        setInfoMsg({ isLoading: false, status: "error", msg: "Există deja un cont pentru adresa de email introdusă!" });
      else if (apiResponse.contWithCodClientExists)
        setInfoMsg({ isLoading: false, status: "error", msg: "Există deja un cont pentru codul client introdus!" });
      else if (apiResponse.contWithNrContractExists)
        setInfoMsg({ isLoading: false, status: "error", msg: "Există deja un cont pentru numărul de contract introdus!" });
      else setInfoMsg({ isLoading: false, status: "error", msg: "Eroare generare link și transmitere email. Vă rugăm să încercați din nou!" });
    } catch (error) {
      console.error(error);
      setInfoMsg({ isLoading: false, status: "error", msg: "Eroare generare link și transmitere email. Vă rugăm să încercați din nou!" });
    }
  };

  return (
    <div>
      <Grid item xs={12} className={classes.buttonsContainer}>
        <Paper className={classes.paper}>
          <form>
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Email client"
              name="email"
              value={formData.email}
              required
              regexTest={EMAIL_REGEX}
              helperText={EMAIL_ERROR}
            />
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Cod client"
              name="codClient"
              value={formData.codClient}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Număr contract"
              name="nrContract"
              value={formData.nrContract}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />
            <Button
              disabled={!validateFormData(formData)}
              className={classes.saveButton}
              variant="contained"
              color="primary"
              onClick={tryPostLinkInvite}
            >
              Generare link și transmitere email
            </Button>
          </form>
          <Spinner
            containerStyle={{
              width: 41,
              height: 41,
              margin: "40px auto 0px auto",
            }}
            show={infoMsg.isLoading}
          />
          {infoMsg.msg != null && (
            <div
              style={{
                padding: "30px 0px",
                // marginTop: 32,
                textAlign: infoMsg.msg.length > 44 ? "justify" : "center",
                fontSize: 18,
                color: infoMsg.isLoading
                  ? theme.palette.primary.main
                  : infoMsg.status === "ok"
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              }}
            >
              {infoMsg.msg}
            </div>
          )}
        </Paper>
      </Grid>
    </div>
  );
}
